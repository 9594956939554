import React from 'react'
import { graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
const axios = require('axios')
import Layout from '../../../components/layout'
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
} from 'reactstrap'
import header from '../../../images/CleanAir-Desktop-Header-1920x340-Curve-DataHub-2x.png'
import headerMobile from '../../../images/CleanAir-Mobile-Header-1080x550-Curve-DataHub-2x.png'
import LinkBack from '../../../components/link-back'

class UnsubscribeTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: null,
      phone_number: null,
      error: false,
      message: null,
      success: false,
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleInputChange(event) {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  handleSubmit(event) {
    event.preventDefault()
    var ts = Math.round(new Date().getTime() / 1000)

    if (this.state.phone_number !== null) {
      axios(
        `https://cleanairgm.com/.netlify/functions/unsubscribe?phone_number=${this.state.phone_number}&ts=${ts}`
      ).then(response => {
        if (response.data.status === 400) {
          this.setState({ error: true, message: response.data.message })
          return
        }

        if (response.data.status === 200) {
          this.setState({ error: false, success: true })
          return
        }

        this.setState({
          error: true,
          message: 'An error has occurred, please try again later.',
        })
      })
    }

    if (this.state.email !== null) {
      axios(
        `https://cleanairgm.com/.netlify/functions/unsubscribe?email=${this.state.email}`
      ).then(response => {
        if (response.data.status === 400) {
          this.setState({ error: true, message: response.data.message })
          return
        }

        if (response.data.status === 200) {
          this.setState({ error: false, success: true })
          return
        }

        this.setState({
          error: true,
          message: 'An error has occurred, please try again later.',
        })
      })
    }

    axios(
      `https://cleanairgm.com/.netlify/functions/unsubscribe?phone_number=${this.state.phone_number}`
    ).then(response => {
      if (response.data.status === 400) {
        this.setState({ error: true, message: response.data.message })
        return
      }

      if (response.data.status === 200) {
        this.setState({ error: false, success: true })
        return
      }

      this.setState({
        error: true,
        message: 'An error has occurred, please try again later.',
      })
    })
  }

  render() {
    const post = get(this.props, 'data.contentfulBasicPage')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')

    return (
      <Layout location={this.props.location}>
        <div style={{ background: '#fff' }}>
          <Helmet title={`Unsubscribe from alerts | ${siteTitle}`} />

          <div className="page-banner page-banner--disclaimer">
            <img className="d-none d-lg-block" src={header} alt="" />
            <img className="d-block d-lg-none" src={headerMobile} alt="" />
            <h1>Unsubscribe from alerts</h1>
          </div>

          <Container className="news mb-5">
            <LinkBack
              to={'/data-hub/forecast-and-alerts'}
              title={'back to Forecast and alerts'}
            />
            <Row>
              <Col>
                <p>
                  You can unsubscribe at any time by completing the form below.
                </p>
              </Col>
            </Row>
          </Container>

          <div className="subscribe-form pt-5 pb-5 mt-5">
            <Container>
              {!this.state.success && (
                <Form onSubmit={this.handleSubmit}>
                  <FormGroup>
                    <Label for="email">Email</Label>
                    <Input
                      type="text"
                      name="email"
                      id="email"
                      value={this.state.email}
                      onChange={this.handleInputChange}
                    ></Input>
                  </FormGroup>
                  <p>
                    <b>Or</b>
                  </p>
                  <FormGroup>
                    <Label for="phone_number">Phone number</Label>
                    <Input
                      type="text"
                      name="phone_number"
                      id="phone_number"
                      value={this.state.phone_number}
                      onChange={this.handleInputChange}
                    ></Input>
                  </FormGroup>
                  {this.state.error && (
                    <Alert color="danger">{this.state.message}</Alert>
                  )}
                  <button className="btn btn-content" type="submit">
                    Submit
                  </button>
                </Form>
              )}
              {this.state.success && (
                <div>
                  <h2>Success</h2>
                  <p>You have successfully unsubscribed</p>
                </div>
              )}
            </Container>
          </div>
        </div>
      </Layout>
    )
  }
}

export default UnsubscribeTemplate

export const pageQuery = graphql`
  query UnsubscribePageBySlug {
    site {
      siteMetadata {
        title
      }
    }
  }
`
